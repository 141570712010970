
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import FBank from "@/components/f/Bank.vue";
import FBranch from "@/components/f/Branch.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    FBank,
    FBranch,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() bank_name: any;
  @Prop() bank_code: any;
  @Prop() branch_name: any;
  @Prop() branch_code: any;
  @Prop() bank_type: any;
  @Prop() bank_num: any;
  @Prop() bank_holder: any;
}

