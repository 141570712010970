import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import vuex_table from '@/vuex/vuex_table';

@Component
export default class mixinCompany extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.company_manager;
  }
  get global() {
    return vuex_data.company_global.get(this);
  }
  get branch() {
    return vuex_data.company_branch.get(this);
  }
  get branch_table() {
    return vuex_table.branch.get(this);
  }

  get menu_options() {
    var options = {};

    options['company'] = {name: '会社', route: 'company_base', pages: ['company_base', 'company_address', 'company_bank', 'company_tax']};
    options['branch'] = {name: '部署/店舗', route: 'branch_base', pages: ['branch_base']};
    
    return options;
  }

  get menu_sub_options() {
    var options = {};

    options['company'] = []
    options['company'].push({name: '基本情報', route: 'company_base', pages: ['company_base']});
    options['company'].push({name: '住所情報', route: 'company_address', pages: ['company_address']});
    options['company'].push({name: '口座情報', route: 'company_bank', pages: ['company_bank']});
    options['company'].push({name: '税・社会保険情報', route: 'company_tax', pages: ['company_tax']});

    options['branch'] = [];
    options['branch'].push({name: '店舗設定', route: 'branch_base', pages: ['branch_base']});

    return options;
  }
}