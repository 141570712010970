
import { Component, Prop, Mixins } from 'vue-property-decorator';
import info_dialog from "@/vuex/info_dialog";
import mixinForm from '@/mixins/mixinForm';

@Component({
  components: {
  }
})
export default class Check extends Mixins(mixinForm) {
  @Prop() title?: string;
  @Prop() content?: string;
  @Prop() link?: string;

  clicked() {
    if (this.content !== undefined) {
      info_dialog.openDialog({title: this.title, msg: this.content});
    } else if (this.link !== undefined) {
      window.open(this.link, '_blank');
    }
  }

  get is_slot() {
    return this.$slots.default !== undefined;
  }
}
