import VuexTableControl from '@/vuex/VuexTableControl';

export default class Employeeinfo extends VuexTableControl {
  name = 'employeeinfo';
  key = 'employee_infos';
  source = 'info';
  sort_keys = [
    {key: 'name', keys: ['data', 'name']},
    {key: 'type', keys: ['data', 'type']},
    {key: 'is_join', keys: ['data', 'is_join']},
    {key: 'is_employee', keys: ['data', 'is_employee']},
    {key: 'is_branch', keys: ['data', 'is_branch']},
  ];
  search_targets = [
    {type: 'normal', keys: ['data', 'name']}, 
  ];
}