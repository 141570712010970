
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import CompanyCompanyBankIsbank from "@/components/company/company/bank/Isbank.vue";
import CompanyCompanyBankBankdisp from "@/components/company/company/bank/bankdisp.vue";
import LItemlevel from "@/components/l/Itemlevel.vue";
import CompanyCompanyBankBankname from "@/components/company/company/bank/bankname.vue";
import CompanyCompanyBankBranchname from "@/components/company/company/bank/branchname.vue";
import CompanyCompanyBankBanktype from "@/components/company/company/bank/banktype.vue";
import CompanyCompanyBankBanknum from "@/components/company/company/bank/banknum.vue";
import CompanyCompanyBankBankholder from "@/components/company/company/bank/bankholder.vue";
import CompanyCompanyBankBankbreak from "@/components/company/company/bank/bankbreak.vue";
import CompanyCompanyBankBizcode from "@/components/company/company/bank/bizcode.vue";
import CompanyCompanyBankTransfertype from "@/components/company/company/bank/transfertype.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    CompanyCompanyBankIsbank,
    CompanyCompanyBankBankdisp,
    LItemlevel,
    CompanyCompanyBankBankname,
    CompanyCompanyBankBranchname,
    CompanyCompanyBankBanktype,
    CompanyCompanyBankBanknum,
    CompanyCompanyBankBankholder,
    CompanyCompanyBankBankbreak,
    CompanyCompanyBankBizcode,
    CompanyCompanyBankTransfertype,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }
}
