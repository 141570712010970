
//VUEX
import {default as shiftgroup} from '@/vuex/config/table/shiftgroup';
import {default as paymentgroup} from '@/vuex/config/table/paymentgroup';
import {default as payment_allowance} from '@/vuex/config/table/payment_allowance';
import {default as payment_deduction} from '@/vuex/config/table/payment_deduction';
import {default as payment_other_allowance} from '@/vuex/config/table/payment_other_allowance';
import {default as bonus_allowance} from '@/vuex/config/table/bonus_allowance';
import {default as bonus_deduction} from '@/vuex/config/table/bonus_deduction';
import {default as bonus_other_allowance} from '@/vuex/config/table/bonus_other_allowance';
import {default as employee_sub_check} from '@/vuex/config/table/employee_sub_check';
import {default as employee_check} from '@/vuex/config/table/employee_check';
import {default as branch} from '@/vuex/company/table/branch';
import {default as employeeinfo} from '@/vuex/config/table/employeeinfo';
import {default as paidhol} from '@/vuex/employee_each/table/paidhol';

const vuex_object = {
  //config
  shiftgroup: new shiftgroup(),
  paymentgroup: new paymentgroup(),
  payment_allowance: new payment_allowance(),
  payment_deduction: new payment_deduction(),
  payment_other_allowance: new payment_other_allowance(),
  bonus_allowance: new bonus_allowance(),
  bonus_deduction: new bonus_deduction(),
  bonus_other_allowance: new bonus_other_allowance(),
  employee_check: new employee_check(),
  employee_sub_check: new employee_sub_check(),
  employeeinfo: new employeeinfo(),

  //company
  branch: new branch(),

  //employee_each
  paidhol: new paidhol(),
};

export default vuex_object;