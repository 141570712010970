import VuexTableControl from '@/vuex/VuexTableControl';

export default class Branch extends VuexTableControl {
  name = 'branch';
  key = 'branches';
  source = 'branch';
  sort_keys = [
    {key: 'name', keys: ['data', 'name']},
  ];
  search_targets = [
    {type: 'normal', keys: ['data', 'name']}, 
  ];
}