
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import dialog from "@/vuex/dialog";
  import FText from "@/components/f/Text.vue";
  import FSelect from "@/components/f/Select.vue";
  import FButton from "@/components/f/Button.vue";
  import FControlEditdelete from "@/components/f/ControlEditdelete.vue";

  @Component({
    components: {
      FText,
      FSelect,
      FButton,
      FControlEditdelete,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() branch: any;
    @Prop() is_branch_bank: boolean;
    @Prop() work_ins_kinds: any;
    @Prop() work_ins_types: any;
    @Prop() branch_employees: any;
    @Prop() global_config: any;

    get raw_object() {
      return this.branch;
    }
    get tmp_object() {
      return this.manager.object.branch;
    }

    erase_branch() {
      if (this.object.id == 1) {
        dialog.openErrorDialog({
          title: "エラー",
          error: [this.$t("cannot_delete_main_branch") as string],
        });
      } else {
        this.erase(this.object.id);
      }
    }

    @Provide("is_edit")
    get is_edit() {
      return this.branch && this.manager.flag == this.raw_object.id;
    }
    @Provide("is_add")
    get is_add() {
      return !this.raw_object && !!this.manager.flag;
    }

    belong_num(branch_id) {
      return this.branch_employees[branch_id] ? this.branch_employees[branch_id].length : 0;
    }
    on_change_work_ins_kind_id(val) {
      let work_ins_type_id = Object.keys(this.work_ins_types[val])[0];
      this.object.work_ins_type_id = work_ins_type_id;
    }

    editBranchBank() {
      this.$emit("editBranchBank");
    }
  }
