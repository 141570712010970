
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';
import { Provide } from '@/library/provide';
import LProgress from "@/components/l/Progress.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LProgress,
    FControl,
  }
})
export default class Pager extends Mixins(mixinForm) {
  @Prop({default: false}) loading: boolean;

  cancel() {
    this.$emit('cancel');
  }
  update() {
    this.$emit('update');
  }
  create() {
    this.$emit('create');
  }
  start() {
    this.$emit('start');
  }
  erase() {
    this.$emit('delete');
  }

  @Provide('is_edit')
  get use_is_edit() {
    if (this.is_edit_custom !== undefined) {
      return this.is_edit_custom;
    } else {
      return this.is_edit;
    }
  }
  @Provide('is_add')
  get use_is_add() {
    if (this.is_add_custom !== undefined) {
      return this.is_add_custom;
    } else {
      return this.is_add;
    }
  }
}
