
import { Component, Prop, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import { VExpansionPanel } from "vuetify/lib";
import { VExpansionPanelContent } from "vuetify/lib";
import FPartsSidemenu from "@/components/f/parts/Sidemenu.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    FPartsSidemenu,
    FSelect,
  }
})
export default class Normal extends Mixins(utilMixins) {
  @Prop() value: string;
  @Prop() options: {[id: string]: {name: string, route: string, pages: string[]}};
  @Prop() sub_options?: {[id: string]: {name: string, route: string, pages: string[]}[]};//サブ項目がある場合(タブを全てサイドメニューに押し込む場合)

  created() {
    if (!this.isContainPage()) {
      const key = Object.keys(this.sub_options)[0];
      const option = this.sub_options[key];
      this.input(option[0].pages[0]);
    }
  }

  isContainPage() {
    var result = false;
    Object.keys(this.sub_options).forEach(key => {
      this.sub_options[key].forEach(option => {
        if (option.pages.indexOf(this.value) !== -1) {
          result = true;
        }
      });
    });
    return result;
  }

  get expand() {
    var expand = {};
    Object.keys(this.options).forEach(key => {
      expand[key] = null;
    })
    expand[this.getKey(this.value)] = 0;
    return expand;
  }
  clickExpand(page) {
    if (page != this.value) {
      this.$emit('input', page);
    }
  }

  getKey(page) {
    var result = null;
    Object.keys(this.options).forEach(key => {
      if (this.options[key].pages.indexOf(page) !== -1) {
        result = key
      }
    });
    return result;
  }

  get sp_page() {
    var result = null;

    Object.keys(this.sub_options).forEach(sub_key => {
      this.sub_options[sub_key].forEach(option => {
        if (option.pages.indexOf(this.page) !== -1) {
          result = option.route;
        }
      });
    }); 

    return result;
  }

  get sp_options() {
    const result = {};

    Object.keys(this.sub_options).forEach(sub_key => {
      if (this.sub_options[sub_key].length == 1) {
        result[this.sub_options[sub_key][0].route] = this.options[sub_key].name;
      } else {
        this.sub_options[sub_key].forEach(option => {
          result[option.route] = this.options[sub_key].name + ' > ' + option.name;
        });
      }
    });

    return result;
  }


  input(page) {
    this.$emit('input', page); 
  }
}
