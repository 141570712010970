
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { Modal } from "ant-design-vue";

  @Component({
    components: {
      Modal,
    },
  })
  export default class Popup extends Vue {
    @Prop({ default: false }) persistent?: boolean;
    @Prop({ default: "750px" }) width: string;
    @Prop() value: boolean;
    @Prop({ default: "cancel" }) type: string; //cancel: キャンセルのみ, exec: キャンセルと実行, ok: OKのみ
    @Prop({ default: "閉じる" }) cancel_button: string;
    @Prop({ default: "実行する" }) exec_button: string;
    @Prop({ default: "exec" }) exec_button_color: string;
    @Prop({ default: "none" }) cancel_button_color: string;
    @Prop({ default: "white" }) backgroundcolor: string;
    @Prop({ default: false }) is_cancel_func: boolean; //これがtrueなら勝手に閉じない

    get is_display() {
      return this.value;
    }
    set is_display(val: boolean) {
      this.$emit("input", val);
    }

    exec_dialog_func() {
      this.$emit("exec");
    }

    cancel_dialog_func() {
      if (this.is_cancel_func) {
        this.$emit("cancel");
      } else {
        this.is_display = false;
      }
    }
  }
