
import { Component, Prop, Vue } from 'vue-property-decorator';
import FSelect from "@/components/f/Select.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    FSelect,
    FText,
  }
})
export default class Control extends Vue {
  @Prop() table: any;
  @Prop() branch_id?: number;
  @Prop() branch_hash?: {[id: number]: string};
  @Prop({default: true}) is_disp_num: boolean;
  @Prop({default: true}) is_search: boolean;
  @Prop({default: '名前/コード'}) placeholder: string;

  disp_num_option = {3: 3, 5: 5, 10: 10, 30: 30, 50: 50, 100: 100};
  store_branch_id = null;

  created() {
    this.store_branch_id = this.branch_id;
  }

  get is_multi_branch() {
    return Object.keys(this.branch_hash).length > 1;
  }

  changeBranchId(val) {
    this.$emit('changeBranchId', val);
  }

  set tmp_disp_num(val) {
    this.table.disp_num = val;
  }
  get tmp_disp_num() {
    return this.table.disp_num;
  }

  set tmp_search_key(val) {
    this.table.search_key = val;
  }
  get tmp_search_key() {
    return this.table.search_key;
  }

  set tmp_branch_id(val) {
    this.store_branch_id = val;
    this.changeBranchId(val);
  }
  get tmp_branch_id() {
    return this.store_branch_id;
  }
  

}
