
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import CompanyCompanyAddressZip from "@/components/company/company/address/Zip.vue";
import CompanyCompanyAddressAddress from "@/components/company/company/address/Address.vue";
import CompanyCompanyAddressTel from "@/components/company/company/address/Tel.vue";
import CompanyCompanyAddressFax from "@/components/company/company/address/Fax.vue";
import CompanyCompanyAddressUrl from "@/components/company/company/address/Url.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    CompanyCompanyAddressZip,
    CompanyCompanyAddressAddress,
    CompanyCompanyAddressTel,
    CompanyCompanyAddressFax,
    CompanyCompanyAddressUrl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }
}
