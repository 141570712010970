
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import CompanyCompanyBaseName from "@/components/company/company/base/Name.vue";
import CompanyCompanyBaseCode from "@/components/company/company/base/Code.vue";
import CompanyCompanyBaseOfficename from "@/components/company/company/base/Officename.vue";
import CompanyCompanyBaseBizname from "@/components/company/company/base/Bizname.vue";
import CompanyCompanyBaseMynum from "@/components/company/company/base/Mynum.vue";
import CompanyCompanyBasePres from "@/components/company/company/base/Pres.vue";
import CompanyCompanyBaseStaff from "@/components/company/company/base/Staff.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    CompanyCompanyBaseName,
    CompanyCompanyBaseCode,
    CompanyCompanyBaseOfficename,
    CompanyCompanyBaseBizname,
    CompanyCompanyBaseMynum,
    CompanyCompanyBasePres,
    CompanyCompanyBaseStaff,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() manager_employees: any;
  @Prop() global_config: any;
  
  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }
}
