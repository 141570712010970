
  import { Component, Mixins } from "vue-property-decorator";
  import mixinCompany from "@/mixins/mixinCompany";
  import dialog from "@/vuex/dialog";
  import LProgress from "@/components/l/Progress.vue";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import LTable from "@/components/l/Table.vue";
  import LTableHead from "@/components/l/Table/Head.vue";
  import LTableBody from "@/components/l/Table/Body.vue";
  import FInfo from "@/components/f/Info.vue";
  import FControl from "@/components/f/Control.vue";
  import CompanyBranchBranchRow from "@/components/company/branch/branch/Row.vue";
  import LTableFoot from "@/components/l/Table/Foot.vue";
  import CompanyBranchDialogBranchbank from "@/components/company/branch/dialog/Branchbank.vue";

  @Component({
    components: {
      LProgress,
      LContent,
      LBread,
      LTable,
      LTableHead,
      LTableBody,
      FInfo,
      FControl,
      CompanyBranchBranchRow,
      LTableFoot,
      CompanyBranchDialogBranchbank,
    },
  })
  export default class Main extends Mixins(mixinCompany) {
    created() {
      this.m.initAddManager({ name: "branch" });
      this.m.initEditManager({ name: "branch" });
      this.m.initEditManager({ name: "branch_bank" });
    }

    addBranch() {
      if (this.header.plan == 0) {
        dialog.openForceConfirmDialog({
          title: "店舗数が上限に達しています",
          msg: "現在ご利用中のプランAでは2店舗以上登録することはできません。<br>BプランかCプランへ変更のうえ、再度店舗追加を行ってください。",
          cancel_button: "キャンセル",
          exec_button: "プラン変更",
          func: this.changePlan,
          args: {},
        });
      } else {
        this.m.add_manager.branch.add({ branch: this.add_branch_object });
      }
    }

    changePlan() {
      this.change_page("OrderChangePlan");
    }

    get add_branch_object() {
      return {
        code: null,
        name: null,
        work_ins_kind_id: 8,
        work_ins_type_id: 53,
        is_special_biz: 0,
      };
    }
  }
