
import { Component, Mixins } from 'vue-property-decorator';
import mixinCompany from '@/mixins/mixinCompany';
import LProgress from "@/components/l/Progress.vue";
import LPage from "@/components/l/Page.vue";
import LBread from "@/components/l/Bread.vue";
import LSidemenu from "@/components/l/Sidemenu.vue";
import FSidemenu from "@/components/f/Sidemenu.vue";
import CompanyCompanyMain from "@/components/company/company/Main.vue";
import CompanyBranchMain from "@/components/company/branch/Main.vue";

@Component({
  components: {
    LProgress,
    LPage,
    LBread,
    LSidemenu,
    FSidemenu,
    CompanyCompanyMain,
    CompanyBranchMain,
  }
})
export default class Main extends Mixins(mixinCompany) {
}
