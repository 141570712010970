import Vue from "vue";
import * as util from "@/util";

export default class VuexTableControl {
  self = null;
  data = Vue.observable({ disp_num: 10, is_open: [], search_key: null, page: 1, sort_key: {} });
  // _disp_num = Vue.observable(null);//表示件数
  // _is_open = Vue.observable([]);//開いたり閉じたりする部分
  // _search_key = Vue.observable({});//検索文字列
  // _page = Vue.observable(1);//ページ
  // _sort_key = Vue.observable({});//ソートしているキーとそのタイプ {name: 'asc'}のように

  //セットするデータ
  name: string; //テーブルにつける名前 shiftgroupなど
  key: string; //テーブル名 shiftgroupsなど
  source: string; //データのソース groupなど
  condition_func: (data: any) => boolean; //sourceのデータに対するフィルタ (employee) => {return employee.id > 10;}のように
  search_targets: { type: string; keys: string[] }[]; //検索にヒットする対象
  sort_keys: { key: string; keys: string[] }[]; //ソートができる対象
  default_sort_keys: {
    key: string;
    keys: string[];
    sort_mode: string;
    func?: (data: any) => number;
  }[]; //ソートがされていない時にデフォルトでソートを聴かせたいもの

  public get(self) {
    this.self = self;
    return this;
  }

  get disp_num() {
    if (this.data.disp_num === null) {
      const local_disp_num = util.getLocalStorage(this.name + "_disp_num");
      if (local_disp_num == null) {
        util.setLocalStorage(this.name + "_disp_num", 10);
        this.data.disp_num = 10;
      } else {
        this.data.disp_num = Number(local_disp_num);
      }
    }
    return this.data.disp_num;
  }
  set disp_num(val) {
    if (val != this.data.disp_num) this.data.page = 1;
    this.data.disp_num = val;
  }

  get is_open() {
    return this.data.is_open;
  }
  clickOpen(val: string) {
    if (this.data.is_open.indexOf(val) === -1) {
      this.data.is_open.push(val);
    } else {
      this.data.is_open.splice(this.data.is_open.indexOf(val), 1);
    }
  }

  get search_key() {
    return this.data.search_key;
  }
  set search_key(val) {
    if (val != this.data.search_key) this.data.page = 1;
    this.data.search_key = val;
  }

  get page() {
    return this.data.page;
  }
  set page(val) {
    this.data.page = val;
  }

  get sort_key() {
    return this.data.sort_key;
  }

  get raw_data() {
    return this.self[this.source][this.key];
  }

  get searched_data() {
    let contents = util.deep_copy(this.raw_data);
    contents = this.condition_data(contents);
    contents = this.search_data(contents);
    contents = this.sort_data(contents);
    return contents;
  }

  get managed_data() {
    let contents = util.deep_copy(this.searched_data);
    contents = this.page_data(contents);
    return contents;
  }
  page_data(contents) {
    return contents.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }

  get row_num() {
    return this.managed_data ? this.managed_data.length : 100;
  }

  get all_index() {
    return this.searched_data.length;
  }

  get start_index() {
    return this.disp_num * (this.page - 1) + 1;
  }
  get end_index() {
    return Math.min(this.all_index, this.disp_num * this.page);
  }
  get max_page() {
    return Math.ceil(this.all_index / this.disp_num);
  }
  get page_num_list() {
    var start_page = Math.max(1, this.page - 2);
    if (this.page > this.max_page - 2) start_page = Math.max(1, this.max_page - 4);
    var end_page = Math.min(this.max_page, this.page + 2);
    if (this.page < 3) end_page = Math.min(this.max_page, 5);
    var page_num_list = [];
    for (var i = start_page; i <= end_page; i++) {
      page_num_list.push(i);
    }
    return page_num_list;
  }

  public sort_class(sort_key) {
    return this.data.sort_key[sort_key] ?? null;
  }
  public sort_click(val: string) {
    if (this.data.sort_key[val] === undefined) {
      Vue.set(this.data, "sort_key", { [val]: "asc" });
    } else if (this.data.sort_key[val] == "asc") {
      Vue.set(this.data, "sort_key", { [val]: "desc" });
    } else {
      Vue.set(this.data, "sort_key", {});
    }
  }

  condition_data(contents) {
    if (this.condition_func) {
      return contents.filter((content) => this.condition_func(content));
    } else {
      return contents;
    }
  }

  search_data(contents) {
    var search_key = this.search_key;
    if (search_key === null) {
      return contents;
    } else {
      var search_keys = search_key.split(/\s+/).filter((str) => str != "");
      return contents.filter((content) => {
        var flag = 0;
        search_keys.forEach((key) => {
          var targets = this.fetchTargets(content, this.search_targets);
          targets.forEach((target) => {
            if (target.type == "kana") {
              if (util.search_kana(target.value, key)) {
                flag = 1;
              }
            } else if (target.type == "normal") {
              if (util.isset(target.value)) {
                if (target.value.toLowerCase().indexOf(key.toLowerCase()) != -1) {
                  flag = 1;
                }
              }
            }
          });
        });
        return flag;
      });
    }
  }

  sort_data(contents) {
    var is_sorted = false;
    if (this.sort_keys) {
      for (var i = 0; i < this.sort_keys.length; i++) {
        if (this.data.sort_key[this.sort_keys[i].key]) {
          contents.sort((x, y) =>
            util.multi_sort_idx([
              {
                x: this.fetchTarget(x, this.sort_keys[i].keys),
                y: this.fetchTarget(y, this.sort_keys[i].keys),
                mode: this.data.sort_key[this.sort_keys[i].key],
              },
            ]),
          );
          is_sorted = true;
          break;
        }
      }
    }

    if (!is_sorted && this.default_sort_keys) {
      contents.sort((x, y) => {
        var sort_keys = [];
        this.default_sort_keys.forEach((sort_key) => {
          if (sort_key.func) {
            sort_keys.push({
              x: sort_key.func(this.fetchTarget(x, sort_key.keys)),
              y: sort_key.func(this.fetchTarget(y, sort_key.keys)),
              mode: sort_key.sort_mode,
            });
          } else {
            sort_keys.push({
              x: this.fetchTarget(x, sort_key.keys),
              y: this.fetchTarget(y, sort_key.keys),
              mode: sort_key.sort_mode,
            });
          }
        });
        return util.multi_sort_idx(sort_keys);
      });
    }
    return contents;
  }

  fetchTargets(content, search_targets) {
    var targets = [];
    search_targets.forEach((target) => {
      targets.push({ type: target.type, value: this.fetchTarget(content, target.keys) });
    });
    return targets;
  }
  fetchTarget(content, search_targets) {
    if (search_targets[0] == "func") {
      return this.fetchTargetKeyFunc(content, search_targets[1], search_targets[2]);
    } else if (search_targets[0] == "self") {
      return this.fetchTargetKey(content, this.self, search_targets.slice(1));
    } else if (search_targets[0] == "data") {
      return this.fetchTargetKey(content, content, search_targets.slice(1));
    } else {
      return this.fetchTargetKey(content, this.self[search_targets[0]], search_targets.slice(1));
    }
  }
  fetchTargetKey(content, parent, search_targets) {
    if (Array.isArray(search_targets[0])) {
      var key = this.fetchTarget(content, search_targets[0]);
    } else {
      var key = search_targets[0];
    }
    if (search_targets.length == 1) {
      if (parent) {
        return parent[key];
      } else {
        return null;
      }
    } else {
      return this.fetchTargetKey(content, parent[key], search_targets.slice(1));
    }
  }
  fetchTargetKeyFunc(content, func_name, search_targets) {
    var args = [];
    search_targets.forEach((search_target) => {
      args.push(this.fetchTarget(content, search_target));
    });
    return this.self[func_name](...args);
  }
}
