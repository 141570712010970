
import { Component, Mixins } from 'vue-property-decorator';
import mixinCompany from '@/mixins/mixinCompany';
import LProgress from "@/components/l/Progress.vue";
import CompanyBranchBranch from "@/components/company/branch/Branch.vue";

@Component({
  components: {
    LProgress,
    CompanyBranchBranch,
  }
})
export default class Main extends Mixins(mixinCompany) {
}
