
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Main extends Vue {
  @Prop() branches;

  clicked_branch(branch) {
    this.$emit('clicked', branch);
  }
}
