import VuexTableControl from '@/vuex/VuexTableControl';

export default class Shiftgroup extends VuexTableControl {
  name = 'shiftgroup';
  key = 'shiftgroups';
  source = 'group';
  sort_keys = [
    {key: 'name', keys: ['data', 'name']},
    {key: 'branch_id', keys: ['data', 'branch_id']},
  ];
  search_targets = [
    {type: 'normal', keys: ['data', 'name']}, 
  ];
}