
import { Component, Mixins } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'office_seiri_code';
    this._info = 'info.socialins_seiri_code';
    this._popup_title = 'office_seiri_code';
    this._popup_content = 'popup.socialins_seiri_code';
    this._popup_img = 'info/company/social_ins_office_code.png';
  }
}
