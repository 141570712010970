
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';

@Component({
  components: {
  }
})
export default class Foot extends Mixins(utilMixins) {
  @Prop() table;

  set tmp_page(val) {
    this.table.page = val;
  }
  get tmp_page() {
    return this.table.page;
  }

  get page_num_list() {
    var start_page = Math.max(1, this.table.page - 2);
    if (this.table.page > this.table.max_page - 2)
      start_page = Math.max(1, this.table.max_page - 4)
    var end_page = Math.min(this.table.max_page, this.table.page + 2);
    if (this.table.page < 3)
      end_page = Math.min(this.table.max_page, 5);
    var page_num_list = [];
    for (var i = start_page; i <= end_page; i++) {
      page_num_list.push(i);
    }
    return page_num_list;
  }
}
