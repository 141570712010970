
import { Component, Mixins } from 'vue-property-decorator';
import mixinCompany from '@/mixins/mixinCompany';
import LProgress from "@/components/l/Progress.vue";
import CompanyCompanyBase from "@/components/company/company/Base.vue";
import CompanyCompanyAddress from "@/components/company/company/Address.vue";
import CompanyCompanyBank from "@/components/company/company/Bank.vue";
import CompanyCompanyTax from "@/components/company/company/Tax.vue";

@Component({
  components: {
    LProgress,
    CompanyCompanyBase,
    CompanyCompanyAddress,
    CompanyCompanyBank,
    CompanyCompanyTax,
  }
})
export default class Main extends Mixins(mixinCompany) {
  created() {
    this.m.initEditManager({name: 'company_base'});
    this.m.initEditManager({name: 'company_address'});
    this.m.initEditManager({name: 'company_bank'});
    this.m.initEditManager({name: 'company_tax'});
  }
}
