import VuexTableControl from "@/vuex/VuexTableControl";

export default class EmployeeCheck extends VuexTableControl {
  name = "employee_check";
  key = "employees";
  source = "employee_now";
  sort_keys = [
    { key: "code", keys: ["data", "code"] },
    { key: "name", keys: ["data", "kana"] },
    { key: "hiring_type", keys: ["data", "hiring_type"] },
    { key: "branch_id", keys: ["data", "branch_id"] },
    { key: "status", keys: ["data", "status"] },
    { key: "retired_on", keys: ["data", "retired_on"] },
  ];
  default_sort_key = [{ key: "retired_on", keys: ["data", "retired_on"], sort_mode: "desc" }];
  search_targets = [
    { type: "normal", keys: ["data", "code"] },
    { type: "normal", keys: ["data", "name"] },
    { type: "kana", keys: ["data", "kana"] },
  ];
}
