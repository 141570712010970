
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinCompany from '@/mixins/mixinCompany';
import CompanyMain from "@/components/company/Main.vue";

@Component({
  components: {
    CompanyMain,
  }
})
export default class Company extends Mixins(mixinCompany) {

}
