
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    LItem,
    FSelect,
  }
})
export default class Main extends Mixins(mixinPart) {
  @Prop() manager_employee_hash: any;

  created() {
    this._title = 'pres';
  }
}
