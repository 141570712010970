
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import FControl from "@/components/f/Control.vue";
import LItemlevel from "@/components/l/Itemlevel.vue";
import CompanyCompanyTaxTaxTaxoffice from "@/components/company/company/tax/Tax/Taxoffice.vue";
import CompanyCompanyTaxTaxSeirinum from "@/components/company/company/tax/Tax/Seirinum.vue";
import CompanyCompanyTaxSocialinsSeiricode from "@/components/company/company/tax/Socialins/Seiricode.vue";
import CompanyCompanyTaxSocialinsOfficenum from "@/components/company/company/tax/Socialins/Officenum.vue";
import CompanyCompanyTaxEmpinsHellowork from "@/components/company/company/tax/Empins/Hellowork.vue";
import CompanyCompanyTaxEmpinsOfficenum from "@/components/company/company/tax/Empins/Officenum.vue";

@Component({
  components: {
    LContent,
    LBread,
    FControl,
    LItemlevel,
    CompanyCompanyTaxTaxTaxoffice,
    CompanyCompanyTaxTaxSeirinum,
    CompanyCompanyTaxSocialinsSeiricode,
    CompanyCompanyTaxSocialinsOfficenum,
    CompanyCompanyTaxEmpinsHellowork,
    CompanyCompanyTaxEmpinsOfficenum,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }
}
