
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FBank from "@/components/f/Bank.vue";

@Component({
  components: {
    LItem,
    FBank,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'bank_name';
    this._need = true;
  }
}
