
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';
import { Provide } from '@/library/provide';
import FText from "@/components/f/Text.vue";

@Component({ 
  components: {
    FText,
  }
})
export default class Name extends Mixins(mixinForm) {
  @Prop() kana: any;
  @Prop() name: any;

  get tmp_name() {
    return this.name;
  }
  set tmp_name(val) {
    this.$emit('name', val);
  }
  get tmp_kana() {
    return this.kana;
  }
  set tmp_kana(val) {
    this.$emit('kana', val);
  }

  @Provide('is_edit')
  get use_is_edit() {
    if (this.is_edit_custom !== undefined) {
      return this.is_edit_custom;
    } else {
      return this.is_edit;
    }
  }
  @Provide('is_add')
  get use_is_add() {
    if (this.is_add_custom !== undefined) {
      return this.is_add_custom;
    } else {
      return this.is_add;
    }
  }
  get is_input() {
    return this.use_is_edit || this.use_is_add;
  }
}
