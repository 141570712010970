import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import * as util from '@/util';
import master_realtime from "@/vuex/master/realtime";
import * as vali from "@/validations/validationUtil";
import EditManager from '@/vuex/VuexEditManager';
import { Inject, Provide } from '@/library/provide';

//ページコンポーネントは、
// config-holiday(
//   :is_edit="m.edit_flag.attendance_punch"
//   :company="company"
//   @cancel="m.cancelAll()"
//   @edit="({id}) => m.startEdit({name: 'attendance_punch', val: id})"
//   @update="({id, object}) => m.update({name: 'base', args: {company_id: id, company: object.company}})"
//   @create="({object}) => m.create({name: 'base', args: {company: object.company}})"
// )

@Component
export default class mixinPage extends Mixins(utilMixins) {
  @Prop() manager: EditManager;

  isInputting(): boolean {
    return this.manager.isInputting();
  } 
  
  state: any = {
    reading_zip: false,
    banks: [],
    branches: [],
    banks1: [],
    branches1: [],
    banks2: [],
    branches2: [],
    taxoffices: [],
  };
  add_object: any = {};

  get is_editted() {
    return this.manager.change_flag;
  }

  get raw_object() {
    return {};//propで渡されたオブジェクトを呼び出し元でかく
  }
  get tmp_object() {
    return {};//this.manager.objectの欲しいオブジェクトを呼び出し元でかく
  }
  set tmp_object(val) {
    //override
  }
  get is_input() {
    return this.is_edit || this.is_add;
  }
  
  get object() {
    return (this.is_edit || this.is_add) ? this.tmp_object as any : this.raw_object as any;
  }
  set object(val) {
    this.tmp_object = val;
  }

  @Provide('is_edit')
  get is_edit() {
    return this.is_edit_inject !== undefined ? this.is_edit_inject : (!!this.manager?.flag ?? false);
  }
  set is_edit(val) {
    if (!val) {
      this.manager.cancel();
    }
  }
  @Provide('is_add')
  get is_add() {
    return this.is_add_inject !== undefined ? this.is_add_inject : ((!!this.manager?.flag && !this.raw_object) ?? false);
  }
  set is_add(val) {
    if (!val) {
      this.manager.cancel();
    }
  }

  @Inject({from: 'is_edit', default: undefined})
  is_edit_inject: boolean | undefined;
  @Inject({from: 'is_add', default: undefined})
  is_add_inject: boolean | undefined;

  cancel() {
    this.manager.cancel();
  }
  add(object: any) {
    this.manager.add(object);
  }
  edit(objects: any, id?: number | boolean) {
    this.manager.edit(objects, id);
  }

  update(id?: number | boolean) {
    this.$emit('update', {id: id, tmp_objects: this.manager.object});
  }
  create() {
    this.$emit('create', {tmp_objects: this.manager.object});
  }
  erase(id?: number) {
    this.$emit('delete', {id: id});
  }
  dialog(args: any) {
    this.$emit('dialog', args);
  }

  public async inputZip({name, zip}) {
    this.state.reading_zip = true;
    const address = await this.readZip(zip);
    if (address !== null) this.manager.object[name].address = address;
    this.state.reading_zip = false;
  }
  public async inputBankName({name, bank_name, prefix = ''}: {name: string, bank_name?: string, prefix?: string}) {
    this.manager.object[name]['bank'+prefix+'_code'] = null;
    this.manager.object[name]['branch'+prefix+'_name'] = null;
    this.manager.object[name]['branch'+prefix+'_code'] = null;
    this.manager.object[name]['bank'+prefix+'_id'] = null;
    this.manager.object[name]['branch'+prefix+'_id'] = null;
    const banks = await this.readBank(bank_name);
    this.state['banks'+prefix] = banks;
  }
  public clickBank({name, bank, prefix = ''}: {name: string, bank: any, prefix?: string}) {
    this.manager.object[name]['bank'+prefix+'_name'] = bank.name;
    this.manager.object[name]['bank'+prefix+'_code'] = bank.bank_code;
    this.manager.object[name]['bank'+prefix+'_id'] = bank.id;
    this.state['banks'+prefix] = [];
  }
   public async inputBranchName({name, branch_name, prefix = ''}: {name: string, branch_name?: string, prefix?: string}) {
    this.manager.object[name]['branch'+prefix+'_code'] = null;
    this.manager.object[name]['branch'+prefix+'_id'] = null;
    const branches = await this.readBranch(this.manager.object[name]['bank'+prefix+'_code'], branch_name);
    this.state['branches'+prefix] = branches;
  }
  public clickBranch({name, branch, prefix = ''}: {name: string, branch: any, prefix?: string}) {
    this.manager.object[name]['branch'+prefix+'_name'] = branch.name;
    this.manager.object[name]['branch'+prefix+'_code'] = branch.branch_code;
    this.manager.object[name]['branch'+prefix+'_id'] = branch.id;
    this.state['branches'+prefix] = [];
  }

  private async readZip(zip: null | string) {
    if (zip !== null) {
      if (!vali.is_zip(zip)) return null;
      return await master_realtime.readZip({zip});
    } else {
      return null;
    }
  }
  private async readBank(bank_name: null | string) {
    if (bank_name !== null)
      return master_realtime.readBank({bank_name});
    else
      return [];
  }
  private async readBranch(bank_code: null | string, branch_name: null | string) {
    if (bank_code !== null && branch_name !== null) {
      if (Number(bank_code) == 9900) {
        if (String(branch_name).length >= 3) {
          
          var branch_type = Number(String(branch_name).slice(0, 1));
          var branch_code = String(branch_name).slice(1, 3);
          
          //もし先頭が0なら振替, 2,3桁めに9をつける
          //そうでないなら総合や普通, 2,3桁目に8をつける
          if (branch_type == 0) {
            //振替口座
            branch_code = branch_code + "9";
          } else {
            //総合・普通口座
            branch_code = branch_code + "8";
          }
          
          return master_realtime.readBranchYucho({bank_code, branch_code});
        } else {
          return [];
        }
      } else {
        return master_realtime.readBranch({bank_code, branch_name});
      }
    } else {
      return [];
    }
  }


  private async readTaxoffice(taxoffice_name: null | string) {
    if (taxoffice_name !== null)
      return await master_realtime.readTaxoffice({taxoffice_name});
    else
      return []
  }
  public async inputTaxofficeName({name, taxoffice_name}: {name: string, taxoffice_name?: string}) {
    this.manager.object[name]['taxoffice_id'] = null;
    this.manager.object[name]['taxoffice_num'] = null;
    const taxoffices = await this.readTaxoffice(taxoffice_name);
    this.state.taxoffices = taxoffices;
  }
  public clickTaxoffice({name, taxoffice}) {
    this.manager.object[name]['taxoffice_id'] = taxoffice.taxoffice_id;
    this.manager.object[name]['taxoffice_num'] = taxoffice.taxoffice_num;
    this.manager.object[name]['taxoffice_name'] = taxoffice.name;
    this.state.taxoffices = [];
  }
}