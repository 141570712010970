
import { Component, Prop, Mixins } from 'vue-property-decorator';
import master_realtime from "@/vuex/master/realtime";
import validation from '@/library/validation';
import mixinForm from '@/mixins/mixinForm';
import FText from "@/components/f/Text.vue";
import FPartsBranch from "@/components/f/parts/Branch.vue";

@Component({ 
  components: {
    FText,
    FPartsBranch,
  }
})
export default class Name extends Mixins(mixinForm) {
  @Prop() bank_code: any;
  @Prop() branch_name: any;
  @Prop() branch_code: any;

  branches: any = [];

  public async inputBranchName(branch_name) {
    this.$emit('branch_name', null);
    this.$emit('branch_code', null);
    this.$emit('branch_id', null);
    if (branch_name === null || this.branch_name !== null && this.branch_name.length > branch_name.length) {
      this.branches = [];
    } else if (validation.isset(branch_name)) {
      const res = await this.readBranch(this.bank_code, branch_name);
      if (res !== null) {
        this.branches = res;
      }
    } else {
      this.branches = [];
    }
  }

  public clickBranch(branch) {
    this.$emit('branch_name', branch.name);
    this.$emit('branch_code', branch.branch_code);
    this.$emit('branch_id', branch.id);
    this.branches = [];
  }

  public async readBranch(bank_code, branch_name) {
    if (validation.isset(bank_code) && validation.isset(branch_name)) {
      if (Number(bank_code) == 9900) {
        if (String(branch_name).length >= 3) {
          
          var branch_type = Number(String(branch_name).slice(0, 1));
          var branch_code = String(branch_name).slice(1, 3);
          
          //もし先頭が0なら振替, 2,3桁めに9をつける
          //そうでないなら総合や普通, 2,3桁目に8をつける
          if (branch_type == 0) {
            //振替口座
            branch_code = branch_code + "9";
          } else {
            //総合・普通口座
            branch_code = branch_code + "8";
          }
          
          return master_realtime.readBranchYucho({bank_code, branch_code});
        } else {
          return [];
        }
      } else {
        return master_realtime.readBranch({bank_code, branch_name});
      }
    } else {
      return [];
    }
  }
}
