import VuexTableControl from '@/vuex/VuexTableControl';

export default class Paymentgroup extends VuexTableControl {
  name = 'paymentgroup';
  key = 'paymentgroups';
  source = 'group';
  sort_keys = [
    {key: 'name', keys: ['data', 'name']},
    {key: 'branch_id', keys: ['data', 'branch_id']},
  ];
  search_targets = [
    {type: 'normal', keys: ['data', 'name']}, 
  ];
}