
import { Component, Prop, Mixins } from 'vue-property-decorator';
import master_realtime from "@/vuex/master/realtime";
import validation from '@/library/validation';
import mixinForm from '@/mixins/mixinForm';
import FText from "@/components/f/Text.vue";

@Component({ 
  components: {
    FText,
  }
})
export default class Name extends Mixins(mixinForm) {
  @Prop() bank_name: any;
  @Prop() bank_code: any;

  banks: any = [];

  public async inputBankName(bank_name) {
    this.$emit('bank_name', null);
    this.$emit('bank_code', null);
    this.$emit('bank_code', null);
    this.$emit('bank_id', null);
    this.$emit('branch_name', null);
    this.$emit('branch_code', null);
    this.$emit('branch_id', null);
    if (bank_name === null || this.bank_name !== null && this.bank_name.length > bank_name.length) {
      this.banks = [];
    } else if (validation.isset(bank_name)) {
      const res = await master_realtime.readBank({bank_name});
      if (res !== null) {
        this.banks = res;
      }
    } else {
      this.banks = [];
    }
  }
  public clickBank(bank) {
    this.$emit('bank_name', bank.name);
    this.$emit('bank_code', bank.bank_code);
    this.$emit('bank_id', bank.id);
    this.banks = [];
  }
}
