
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LDialog from "@/components/l/Dialog.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import CompanyBranchDialogBranchbankIsbank from "@/components/company/branch/dialog/branchbank/Isbank.vue";
import CompanyCompanyBankBankdisp from "@/components/company/company/bank/bankdisp.vue";
import LItemlevel from "@/components/l/Itemlevel.vue";
import CompanyBranchDialogBranchbankBankname from "@/components/company/branch/dialog/branchbank/bankname.vue";
import CompanyBranchDialogBranchbankBranchname from "@/components/company/branch/dialog/branchbank/branchname.vue";
import CompanyCompanyBankBanktype from "@/components/company/company/bank/banktype.vue";
import CompanyCompanyBankBanknum from "@/components/company/company/bank/banknum.vue";
import CompanyCompanyBankBankholder from "@/components/company/company/bank/bankholder.vue";
import CompanyCompanyBankBankbreak from "@/components/company/company/bank/bankbreak.vue";
import CompanyCompanyBankBizcode from "@/components/company/company/bank/bizcode.vue";
import CompanyCompanyBankTransfertype from "@/components/company/company/bank/transfertype.vue";

@Component({
  components: {
    LDialog,
    LContent,
    LBread,
    CompanyBranchDialogBranchbankIsbank,
    CompanyCompanyBankBankdisp,
    LItemlevel,
    CompanyBranchDialogBranchbankBankname,
    CompanyBranchDialogBranchbankBranchname,
    CompanyCompanyBankBanktype,
    CompanyCompanyBankBanknum,
    CompanyCompanyBankBankholder,
    CompanyCompanyBankBankbreak,
    CompanyCompanyBankBizcode,
    CompanyCompanyBankTransfertype,
  }
})
export default class Main extends Mixins(mixinPage) {
  get is_display() {
    return !!this.manager.flag;
  }
  set is_display(val: boolean) {
    if (!val) this.manager.cancel();
  }

  get tmp_object() {
    return this.manager.object.branch;
  }
}
