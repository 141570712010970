
import { Component, Mixins } from 'vue-property-decorator';
import mixinPart from '@/mixins/mixinPart';
import LItem from "@/components/l/Item.vue";
import EmployeeeachSelfBankBankdisp from "@/components/employeeeach/self/bank/bankdisp.vue";

@Component({
  components: {
    LItem,
    EmployeeeachSelfBankBankdisp,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'company_bank';
  }

  get condition() {
    return this.object.is_bank == 1 && !this.is_edit;
  }
}
