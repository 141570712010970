
import { Component, Prop, Mixins } from 'vue-property-decorator';
import master_realtime from "@/vuex/master/realtime";
import mixinForm from '@/mixins/mixinForm';
import { Inject, Provide } from '@/library/provide';
import validation from '@/library/validation';
import FText from "@/components/f/Text.vue";

@Component({ 
  components: {
    FText,
  }
})
export default class Name extends Mixins(mixinForm) {
  @Prop() taxoffice_id: any;
  @Prop() taxoffice_num: any;
  @Prop() taxoffice_name: any;

  taxoffices: any = [];

  private async readTaxoffice(taxoffice_name: null | string) {
    if (taxoffice_name !== null)
      return await master_realtime.readTaxoffice({taxoffice_name});
    else
      return []
  }
  public async inputTaxofficeName(taxoffice_name: string) {
    this.$emit('taxoffice_name', null);
    this.$emit('taxoffice_id', null);
    this.$emit('taxoffice_num', null);

    if (taxoffice_name === null || this.taxoffice_name !== null && this.taxoffice_name.length > taxoffice_name.length) {
      this.taxoffices = [];
    } else if (validation.isset(taxoffice_name)) {
      const res = await await this.readTaxoffice(taxoffice_name);
      if (res !== null) {
        this.taxoffices = res;
      }
    } else {
      this.taxoffices = [];
    }
  }
  public clickTaxoffice(taxoffice) {
    this.$emit('taxoffice_id', taxoffice.taxoffice_id);
    this.$emit('taxoffice_num', taxoffice.taxoffice_num);
    this.$emit('taxoffice_name', taxoffice.name);
    this.taxoffices = [];
  }

  @Provide('is_edit')
  get use_is_edit() {
    if (this.is_edit_custom !== undefined) {
      return this.is_edit_custom;
    } else {
      return this.is_edit;
    }
  }
  @Provide('is_add')
  get use_is_add() {
    if (this.is_add_custom !== undefined) {
      return this.is_add_custom;
    } else {
      return this.is_add;
    }
  }
}
